/* Error Animation */

.xmark__circle {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: red;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    animation: color 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
  
  }
  .xmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px red;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  .xmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 29;
    stroke-dashoffset: 29;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px red;
    }
  }
  @keyframes color{
    0%{
      fill: #fff;
    }
    100%{
      fill: red;
    }
  }

  .toastError{
    font-size: 3rem!important;
    font-weight: 200;
    font-family: 'MC-Light';
  }