@font-face {
  font-family: 'MC-BOLD';
  src: url(./fonts/MarkForMCNrwO-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'MC-Light';
  src: url(./fonts/MarkForMCNrwO-Light.ttf) format('truetype');
}

.headerLogo{
    /* width:50%; */
    height:150px;
    margin-top: 55px;
    margin-left: 70px;
    /* vertical middle align */
    display: inline-block;
    vertical-align: middle

}

.headerBar{
    background-color: black!important;
    height: 266px;
    /* height: 14%; */
}

/* .myvideo {
  width: 100%;
  height: 100;
} */

.video {
  width: 100%;
  height: 87vh;
  object-fit: cover;
}

.content {
  width: 100%;
  height: 90%;
  position: absolute;
  top: 0%;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
}


.donateButtonText{
  font-weight: lighter;
  padding-right: 20px!important;
  font-family: 'MC-Light';


}

.donateButton{
  width: 45%;
  
  height: 138px;
}

.payWall{
  /* background-image: url('../assets/plant.jpg'); */
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255,255,255,0) 100%), url('../assets/Donation_BG.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 35vh;
  width: 100%;
  /* bottom gradient */
}

.payWallContent{
  /* margin-top: 470px; */
  margin: 0 auto;
  align-items: center;
  width: 95%;
  display: flex;
  height: 95%;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-start;
}

.payWallGradient{

  height: 50vh;
  width: 100%;
  /* bottom gradient */
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 100%), url('../assets/plant.jpg');
}

.bodyHeader{
  color: white;
  margin: 0 80px 0 80px;
  font-size: 6rem;
  font-weight: 200;
  font-family: 'MC-Light';
}

.donateHeader{
  color: white;
  /* padding-top: 10px; */
  margin-bottom: -30px!important;
  margin: 0 auto;
  font-size: 4rem;
  font-weight: 200;
  font-family: 'MC-Light';
}

.bodyText{
  color: white;
  margin: 0 80px 0 80px;
  font-size: 3rem;
  font-weight: 200;
  font-family: 'MC-Light';
}

/* .toast {
  top: 50%!important;
  left: 50%!important;
  width: 800px!important;
  height: 800px!important;
  transform: translate(-50%, -50%)!important;
  border-radius: 100%!important;
  width: 800px!important;
  height: 800px!important;
  background-color: #fff!important;
  text-align: center;
  font-size: 28px!important;
  display: flex!important;
} */


.treeHolder{
  /* width: 55%; */
  /* margin: 0 auto; */
  padding: 20px;
  display: inline-flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center!important; */
}

.legalDisclosure{
  font-size: .75rem;
  color: white;
  opacity: 75%;
  width: 80%;
  /* margin: 0 80px 0 80px; */
  margin-top: 30px;
  font-weight: 200;
  font-family: 'MC-Light';

}

.modalPaymentHeader{
  font-size: 3rem!important;
  padding-top: 10rem!important;
  font-weight: 200;
  font-family: 'MC-Light';
}


.checkmark__circle {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: color 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
@keyframes color{
  0%{
    fill: #fff;
  }
  100%{
    fill: #7ac142;
  }
}


.toastCompletion{
  font-size: 3rem!important;
  font-weight: 200;
  font-family: 'MC-Light';
}

