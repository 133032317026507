@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MC-Bold';
    src: url('./styles/fonts/MarkForMCNrwO-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'MC-Light';
    src: url('./styles/fonts/MarkForMCNrwO-Light.ttf') format('truetype');
}


body{
    overflow: hidden;
    background-color: black;
}
/* 
.Toastify__toast-container--top-center {
    top: 50%!important;
    left: 50%!important;
    width: 800px!important;
    height: 800px!important;
    transform: translate(-50%, -50%)!important;
}

.Toastify__toast, .Toastify__toast-theme--light, .Toastify__toast--default{
    border-radius: 100%!important;
    width: 800px!important;
    height: 800px!important;
    background-color: #fff!important;
    text-align: center;
    font-size: 28px!important;
} */

#payment-in-progress, .Toastify__toast-container--top-center {
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
    border-radius: 4%!important;
    width: 600px!important;
    height: 700px!important;
    background-color: #fff!important;
    text-align: center;
    /* font-size: 68px!important; */
    display: flex!important;
    padding:70px;
    color: black;
    font-family: 'MC-Light';
}

#payment-success, .Toastify__toast-container--top-center {
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
    border-radius: 4%!important;
    width: 600px!important;
    height: 700px!important;
    background-color: #fff!important;
    text-align: center;
    /* font-size: 68px!important; */
    display: flex!important;
    padding:70px;
    color: black;
    font-family: 'MC-Light';

}

#payment-in-error, .Toastify__toast-container--top-center {
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
    border-radius: 4%!important;
    width: 600px!important;
    height: 700px!important;
    background-color: #fff!important;
    text-align: center;
    /* font-size: 68px!important; */
    display: flex!important;
    padding:70px;
    color: black;
    font-family: 'MC-Light';

}

.Toastify__toast-container--top-center{
    width: 100%!important;
    height: 100%!important;
    border-radius: 0!important;
    background-color: rgba(0, 0, 0, 0.5)!important;
    font-family: 'MC-Light';

}

