@font-face {
  font-family: 'MC-BOLD';
  src: url(./fonts/MarkForMCNrwO-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'MC-Light';
  src: url(./fonts/MarkForMCNrwO-Light.ttf) format('truetype');
}

.thankyou{
    color: white;
    font-family: 'MC-Light';
  }
  
  .video {
    width: 100%;
    height: 68vh;
    object-fit: cover;
  }
  
  .bodyHeader{
    color: white;
    margin: 20px 80px 0 80px;
    font-size: 6rem;
    font-weight: 200;
    font-family: 'MC-Light';
  }

